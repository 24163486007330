<template>
  <div>
    <!-- 标题 -->
    <!-- <div>
      <van-nav-bar
        title="社区风采"
      />
    </div> -->
    <!-- tab栏 -->
    <div class="information">
          <!-- 社区风采 -->
          <div class="center" v-for="item in list" :key="item.index">
            <router-link :to="'/scenery/detail/' + item.RIId">
              <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
              <div class="Content">
                <div style="display: inline-block" class="ContentA">
                  <img :src="item.ThematicUrl" alt="" />
                </div>
                <div style="display: inline-block" class="ContentB">
                  <span class="van-multi-ellipsis--l3">{{ item.Summary }}</span>
                  <br />
                  <span class="centertime">{{ item.IssTime }}</span>
                </div>
              </div>
            </router-link>
          </div>
      <!-- 没有更多数据了 -->
      <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div>
    </div>
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
import { WeGetRealInfoPage } from "@/api/RealInfo";
export default {
  data() {
    return {
      riKind: 0, //tab栏
      list: {}, //资讯列表
      listfrom: {}, //查询传参
      hidden: true, //没有更多数据了
    };
  },
  methods: {
    // 获取分页列表
    getList() {
      this.listfrom.riKind = this.riKind;
      WeGetRealInfoPage({riKind:6})
        .then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              // this.hidden = true;
              this.list = {};
            } else {
              this.list = res.data.data;
              // this.hidden = false;
              for (var i = 0; i < this.list.length; i++) {
                // console.log(this.list[i].IssTime);
                var item = this.list[i].IssTime;
                item = this.dataFormat(item);
                this.list[i].IssTime = item
                if (this.list[i].ThematicUrl == "") {
                  this.list[i].ThematicUrl =
                    "https://cuiyunapi.dexian.ren//upload/images/2021/7/873b94ff-81d.png";
                }
              }
            }
          }
        })
        .catch(() => {});
    },
    // tan点击切换事件
    onClick(e) {
      this.riKind = e;
      this.getList();
    },
    // 时间格式化
    dataFormat(value) {
        var year = value.substr(0, 4)
        var month = value.substr(5, 2)
        var day = value.substr(8, 2)
        var hour = value.substr(11, 2)
        var min = value.substr(14, 2)
        var second = value.substr(17,2)
        return year + "-" + month + "-" + day + " " + hour + ":" + min + ":"+second
      },
  },
  mounted() {
    window.wx.miniProgram.postMessage({ data: { title:'社区风采'} });
    this.getList();
  },
};
</script>
<style>
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
</style>